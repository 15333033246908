<script>
import { mapGetters } from 'vuex';

import { TICKETS_MODULE } from '../../../constants';
import FilteringControllerMixin from '@/mixins/FilteringControllerMixin';
import NumberMixin from '@/mixins/NumberMixin';
import ColorUtilities from '@/utilities/ColorUtilities';

import FilteringMenu from '@/components/molecules/FilterButton/FilteringMenu';
import SortButton from '@/components/molecules/SortButton';

export default defineNuxtComponent({
  components: { SortButton, FilteringMenu },
  mixins: [FilteringControllerMixin, NumberMixin],
  props: {
    ticketStatus: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    ticketsNumber: {
      type: Number,
      required: true,
      default: 0,
    },
    sortCriteria: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      TICKETS_MODULE,
    };
  },
  computed: {
    ...mapGetters('FilteringStore', ['modulesFilters']),
    ...mapGetters('TicketsModuleStore', [
      'selectedTickets',
      'processingAction',
    ]),
    errorColor() {
      return ColorUtilities.ERROR;
    },
    getExportLabel() {
      let label = 'Export ';
      label
        += this.selectedTickets.length > 0
          ? `selection` + ` (${this.selectedTickets.length})`
          : 'all';
      return label;
    },
  },
  methods: {
    onExportAll() {
      this.$emit('exportSelectedTickets');
    },
    onSortCriteriaChanged(sortParameter, selectedCriteria) {
      this.$emit('onSortCriteriaChanged', sortParameter, selectedCriteria);
    },
    onClearFilters() {
      this.$emit('onClearFilters');
    },
    filteringChanged() {
      // This is used to force the header to update after filtering from other components is changed
      this.$forceUpdate();
    },
  },
});
</script>

<template>
  <div class="flex flex-col">
    <div class="flex items-center">
      <div class="text-4xl font-light">
        {{ ticketStatus?.description || __('Loading...') }}
      </div>
      <div
        class="ml-4 flex h-12 w-12 items-center justify-center rounded-full bg-white text-xs text-neutral-1"
      >
        {{ processingAction ? '...' : abbreviate(ticketsNumber, 1) }}
      </div>

      <div class="ml-auto flex space-x-4">
        <SortButton
          :sort-criteria="sortCriteria"
          class="hover:shadow-md"
          @sort-criteria-changed="onSortCriteriaChanged"
        />
        <trailblazer-button
          v-if="hasAvailableFilters(TICKETS_MODULE)"
          danger
          size="m"
          icon="close"
          class="flex bg-white hover:shadow-md"
          @click="onClearFilters"
        >
          {{ __('Clear filters') }}
        </trailblazer-button>
        <trailblazer-button
          icon="filter"
          class="rounded bg-white"
          size="m"
          type="button"
          variant="secondary"
          @click="toggleFilteringMenu"
        >
          {{ __('Filter') }}
        </trailblazer-button>
        <trailblazer-button
          v-if="$auth.can('ticket-export')"
          icon="download"
          class="border-2 border-transparent bg-white"
          size="m"
          type="button"
          variant="secondary"
          @click="onExportAll"
        >
          {{ getExportLabel }}
        </trailblazer-button>
      </div>
    </div>
    <div v-if="isFilteringMenuOpened" class="mt-3">
      <FilteringMenu
        module="tickets"
        class="rounded p-6"
        @filtering-changed="filteringChanged"
      />
    </div>
  </div>
</template>
